<template>
  <div class="wrapper">
    <!-- loader -->
    <div class="d-flex flex-column" v-if="isLoading">
      <div class="flex justify-center">
        <v-img max-width="600" :src="require('@/assets/img/prepare.svg')"></v-img>
      </div>
      <span class="text-center text-h5 leaf--text mt-3">
        {{ message }}
      </span>
      <span class="d-flex justify-center mt-3">
        <v-progress-circular :size="30" color="green" indeterminate></v-progress-circular>
      </span>
    </div>
    <div class="d-flex flex-column" v-if="isClosed">
      <div class="flex justify-center">
        <v-img :max-width="showOperation ? '500' : '600'" :src="require('@/assets/img/close.svg')"></v-img>
      </div>
      <span class="text-center text-h5 leaf--text mt-3">
        {{ message }}
      </span>
      <span class="d-flex justify-center mt-3" v-if="showOperation">
        <v-card class="pa-4 mb-3" outlined elevation="2" :width="$vuetify.breakpoint.mobile ? '90%' : '25%'">
          <v-row>
            <v-col>
              <div class="nunito text-h6 text-center leaf--text text-capitalize">
                Our operation time
              </div>
            </v-col>
          </v-row>
          <v-row v-for="(value, i) in operations" :key="i">
            <v-col>
              <div class="d-flex">
                {{ value.date.start }} - {{ value.date.end }}
              </div>
            </v-col>
            <v-col>{{ dayjs(value.time.from).format("hh:mm A") }} -
              {{ dayjs(value.time.to).format("hh:mm A") }}
            </v-col>
          </v-row>
        </v-card>
      </span>
    </div>
    <div></div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import jwt from "jsonwebtoken";
import { setOptions, bootstrap } from "vue-gtag";

export default {
  name: "HelloWorld",

  data() {
    return {
      isOpenFromMultipleTimeSlot: false,
      isLoading: false,
      isClosed: false,
      showOperation: false,
      operations: null,
      message: "Opening store",
      isError: false,
      data: {
        email: "",
        password: "",
      },
      gid: {
        config: { id: "UA-45208413-12" },
      },
    };
  },

  computed: {
    ...mapGetters({
      product: "getProduct",
      franchise: "getFranchise",
      user: "getUsers",
    }),
    dayjs() {
      return require("dayjs");
    },
  },

  watch: {
    product: "updatedProduct",
    franchise: "updatedFranchise",
  },

  methods: {
    updatedFranchise() {
      // console.log("framchise", this.franchise);
      if (this.franchise) {
        // if (new Date() > new Date(this.franchise.expired_at)) {
        //     this.isLoading = false;
        //     this.isClosed = true;
        //     this.message = `${this.franchise.name} is now closed`;
        //   }
        let expiry =
          new Date() > new Date(this.franchise.expired_at) ? true : false;
        let offline = this.franchise.offline;
        // this.dateslot_check(this.franchise.date_slot);
        if (expiry == true || offline == true) {
          this.checkValidity();
        } else {
          Promise.all([
            this.exception_check(),
            this.dateslot_check(),
            this.timeLoop(),
          ]).then((values) => {

            if (values[0].length > 0) {
              values[0].forEach((d) => {
                this.isClosed = d.isClosed;
                this.isLoading = d.isLoading;
                this.message = d.message;
              });
            } else if (values[1] && Object.keys(values[1]).length > 0) {
              this.isClosed = values[1].isClosed;
              this.isLoading = values[1].isLoading;
              this.message = values[1].message;
              this.showOperation = values[1].showOperation || false;
              this.operations = values[1].operations || [];
            } else if (values[2].length > 0) {
              values[2].forEach((d) => {
                this.isClosed = d.isClosed;
                this.isLoading = d.isLoading;
                this.message = d.message;

                this.isClosed = d.isClosed;
                this.isLoading = d.isLoading;
                this.message = d.message;
                this.showOperation = d.showOperation;
                this.operations = d.operations;
              });
            } else this.post_check();
          });

          // this.franchise.day_slot
          //   ? this.dateslot_check(this.franchise.day_slot)
          //   : this.timeslot_check();
          // let checker = this.timeslot_check();
          // if (checker && Object.keys(checker).length > 0) {
          //   this.isClosed = checker.isClosed;
          //   this.isLoading = checker.isLoading;
          //   this.message = checker.message;
          //   this.showOperation = checker.showOperation;
          //   this.operations = checker.operations;
          // } else this.post_check();
        }
      }
    },

    initGA(_id) {
      if (_id) this.gid.includes = [{ id: _id }];
      setOptions(this.gid);

      bootstrap();

      this.$gtag.pageview("/product");
    },

    updatedProduct() {
      if (this.product && this.franchise) {
        this.$router.push({
          name: "Page",
          params: { child: this.$route.params.child, page: "products" },
        });
      }
    },

    tf_disp(t) {
      var ta = t.split(":"),
        am = "AM";
      var hr = parseInt(ta);
      if (hr > 11) {
        hr -= 12;
        am = "PM";
      }

      var time = hr != 0 ? `${hr}:${ta[1]}${am}` : `12:${ta[1]}${am}`;

      return time;
    },
    post_check() {
      if (!this.isClosed) {
        if (this.$route.query.k) {
          let userz = jwt.verify(this.$route.query.k, "SecretPie");
          this.$store.dispatch("insertUser", userz);
        } else this.$store.dispatch("fetchUsers");
        this.$store.dispatch("fetchProduct");

        if (
          this.franchise.facebook_pixel_id &&
          this.franchise.facebook_pixel_id !== ""
        ) {
          window.fbq("init", this.franchise.facebook_pixel_id);
          window.fbq("track", "PageView");
        }
        if (
          this.franchise.google_analytic_id &&
          this.franchise.google_analytic_id !== ""
        ) {
          this.initGA(this.franchise.google_analytic_id || null);
        }
      }
    },
    tf_num(t) {
      t = t.replace(":", "");
      return parseInt(t);
    },
    checkValidity() {
      this.isLoading = false;
      this.isClosed = true;
      this.message = `${this.franchise.name} is now closed`;
    },
    exception_check(v) {
      v = v || this.franchise.exception_day;
      let closed = v && v.length > 0 ? v.filter((d) => d.closed) : [];
      let k = [];
      if (closed.length > 0) {
        closed.forEach((d) => {
          let today = parseInt(dayjs(new Date()).format("DDMMYYYY")),
            start = parseInt(dayjs(d.date.start).format("DDMMYYYY")),
            end = parseInt(dayjs(d.date.end).format("DDMMYYYY"));

          // console.log("here", today, start, end);
          // console.log("first", today >= start ? true : false);
          // console.log("second", today <= end ? true : false);

          if (today >= start && today <= end) {
            k.push({
              isLoading: false,
              isClosed: true,
              message: `${this.franchise.name} is closed for ${d.name}. ${this.franchise.name} is out from ${d.date.start} to ${d.date.end}. `,
            });
          }
        });
      }
      return k;
    },
    checkOpen(v) {
      let week = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      let first = week.findIndex((d) => d == v.date.start);
      let last = week.findIndex((d) => d == v.date.end);
      let weekly = week.slice(first, last + 1);

      let today = dayjs(new Date()).format("dddd");

      // console.log("week", weekly);
      // console.log("today", today);

      return weekly.includes(today);
    },
    dateslot_check(v) {
      v = v || this.franchise.day_slot;
      // console.log(v, 'v');
      // console.log(this.franchise.day_slot, 'slot');
      let open = v && v.length > 0 ? v.filter((d) => d.status) : [];
      if (open.length > 0) {
        let test = [];
        open.forEach((d) => {
          if (this.checkOpen(d)) {
            test.push({ ...d });
          }
        });
        if (test.length == 0) {
          return {
            isLoading: false,
            isClosed: true,
            showOperation: true,
            operations: open,
            message: `${this.franchise.name} is currently closed`,
          };
        }
      }
    },

    timeLoop() {
      let open = this.franchise.day_slot ? this.franchise.day_slot.filter((d) => d.status) : [];
      let k = [];


      // let checkDay = () => {};

      if (this.franchise.day_slot && this.franchise.day_slot.length > 0) {
        this.franchise.day_slot.forEach((d) => {
          if (this.checkOpen(d)) {
            var from_time = parseInt(dayjs(d.time.from).format("HHmm")),
              to_time = parseInt(dayjs(d.time.to).format("HHmm"));
            var current_time = parseInt(dayjs().format("HHmm"));

            // console.log("here", current_time, from_time, to_time);
            // console.log("first", current_time >= from_time ? true : false);
            // console.log("second", current_time <= to_time ? true : false);
            // console.log('is close', current_time >= from_time && current_time <= to_time);

            if (current_time >= from_time && current_time <= to_time) {
              this.isOpenFromMultipleTimeSlot = true
              // all working
            } else {
              // k.push({
              //   isLoading: false,
              //   isClosed: true,
              //   showOperation: true,
              //   operations: open,
              //   message: `${this.franchise.name} is currently closed`,
              // });
            }
          }
        });
        console.log('isOpenFromMultipleTimeSlot', this.isOpenFromMultipleTimeSlot);

        if (this.isOpenFromMultipleTimeSlot == false) {
          k.push({
            isLoading: false,
            isClosed: true,
            showOperation: true,
            operations: open,
            message: `${this.franchise.name} is currently closed`,
          });
        }
        return k;
      } else {
        let l = this.timeslot_check();
        if (l) {
          k.push(this.timeslot_check());
        }
        return k;
      }
    },

    timeslot_check(timeslot) {
      if (!timeslot || timeslot.length == 0) {
        if (this.franchise.timeslots && this.franchise.timeslots.length > 1) {
          timeslot = this.franchise.timeslots;
        } else {
          timeslot = [];
          //No timeslot
          // if (this.franchise.offline == true ) { //Outlet is not set to offline
          //   $session.offline = false;
          //   valid_message = '';
          // }
        }
      }
      if (timeslot.length > 1) {
        var from_time = timeslot[0],
          to_time = timeslot[timeslot.length - 1];
        var current_time = parseInt(dayjs().format("HHmm"));
        if (
          current_time >= this.tf_num(from_time) &&
          current_time <= this.tf_num(to_time)
        ) {
          //Everything ok
          // if ($session.offline == true && def_offline == false) { //Outlet is not set to offline
          //   $session.offline = false;
          //   valid_message = '';
          // }
        } else {
          return {
            isLoading: false,
            isClosed: true,
            message: `${this.franchise.name
              } is now closed. Our operating time is from ${this.tf_disp(
                from_time
              )} to ${this.tf_disp(to_time)}`,
          };
        }
      }
    },
  },
  beforeMount() {
    this.$gtag.event("Main Page", { method: "Click" });
    this.isLoading = true;
    if (!this.$route.params.child) {
      // this.isLoading = false;
      window.location.href = "https://www.getorders.app/";
    } else {
      this.$store
        .dispatch("fetchFranchise", this.$route.params.child)
        .then((r) => {
          if (r.error) {
            this.isLoading = false;
            this.isClosed = true;
            this.message = "Shop not found, please recheck the shop link";
          }
        });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.text-theme {
  color: #98fb98;
}

.hello {
  display: flex;
  justify-content: center;
}
</style>
